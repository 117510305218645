// import axios from 'axios'
// import eventBus from '@/event-bus'

const classic = {
    namespaced: true,
    state: {
        init: true,
        search: false,
        results: [],
        searchLoader: true,
        unlocks: {},
        pagination: {},
        keywords: {},
        requestFrom: {},
    },
    mutations: {
        updateProperties(state, properties) {
            Object.assign(state, properties)
            state.search = true
        },
        setObg (state){
            state.init = false
            state.search = false
            state.pagination = {
                totalPages: 1,
                totalResults: 0
            }

            state.keywords = {
                suggestions: [],
                maxKeywords: 4
            }

            state.requestFrom = {
                page: 1,
                saved: false,
                keywords: [],
                droppers:{
                    sort: {
                        title: 'Sort by',
                        selected: {id: 0, value: 'New'},
                        allowEmpty: false,
                        searchable: false,
                        maxW:'140px',
                        options: [
                            {id: 0, value: 'New'},
                            {id: 1, value: 'Old'},
                            {id: 2, value: 'Orders'},
                            {id: 3, value: 'Margin'},
                        ]
                    },
                    time: {
                        title: 'Range',
                        selected: {id:0, value: 'All'},
                        allowEmpty: false,
                        searchable: false,
                        maxW:'160px',
                        options: [
                            {id:0, value: 'All'},
                            {id:1, value: 'Last 7 days'},
                            {id:2, value: 'Last 14 days'},
                            {id:3, value: 'Last 30 days'},
                            {id:4, value: 'Previous month'},
                        ]
                    },
                    categories: {
                        title: 'Categories',
                        selected: {id:0, value: 'All'},
                        allowEmpty: false,
                        searchable: true,
                        maxW:'220px',
                        options: []
                    }
                },
            }
        },
        resetObj (state) {
            state.init = false
            state.search = false
            state.pagination = {
                totalPages: 1,
                totalResults: 0
            }

            state.keywords = {
                suggestions: [],
                maxKeywords: 4
            }

            state.requestFrom.page = 1
            state.requestFrom.saved = false
            state.requestFrom.keywords = []
            state.requestFrom.droppers.sort.selected = {id: 0, value: 'New'}
            state.requestFrom.droppers.time.selected = {id:0, value: 'All'}
            state.requestFrom.droppers.categories.selected = {id:0, value: 'All'}
        },
        updateSpins(state, spins) {
            this.spins = spins
        },
        setSelect(state, properties){
            state.requestFrom.droppers[properties.target].selected = properties.value
        },
        setProperty(state, { keys, value }) {
            let target = state;
            for (let i = 0; i < keys.length - 1; i++) {
              target = target[keys[i]];
            }
            target[keys[keys.length - 1]] = value;
        },
    },

    actions: {
        commit({commit}, key, value) {
            const keys = key.split('.');
            commit(`setProperty`, { keys, value });
        }
    }
    
}
  
export default classic