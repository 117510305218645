import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import eventBus from './event-bus'
import VTooltip from 'v-tooltip'
import VueObserveVisibility from 'vue-observe-visibility'

import store from './store'
import 'hooper/dist/hooper.css';
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './assets/global.scss'

Vue.use(VTooltip)
Vue.use(VueObserveVisibility)

Vue.config.productionTip = false
Vue.prototype.axios = axios
Vue.prototype.eventBus = eventBus

new Vue({
  router,
  store,
  directives: {
    vTooltip: VTooltip
  },
  render: function (h) { return h(App) }
}).$mount('#app')
