<template>
  <div v-if="!isServerAccessible" class="server-check">
    <div class="center-container">
      <div class="text">
        Reconnecting to server
      </div>
      <div class="box">
        <div class="comp"></div>
        <div class="loader"></div>
        <div class="con"></div>
        <div class="byte"></div>
        <div class="server"></div>
      </div>
      <div class="massage">
        <p>
          <b>
            Ooops... looks like you are offline!
          </b><br>
          Please check your internet connection or wait for a moment. We are trying to reconnect with the server.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isServerAccessible: true,
    };
  },
  methods: {
    checkServerAccessibility(serverUrl) {
      setInterval(() => {
        if (!this.$store.state.auth.status) {
          return;
        }
        fetch(serverUrl)
            .then(response => {
              if (!response.ok) {
                this.isServerAccessible = false;
              }else {
                this.isServerAccessible = true;
              }
            })
            .catch(error => {
              console.error('Server is not accessible: ', error);
            });
      }, 30000); // 30000 milliseconds = 30 seconds
    },
  },
  mounted() {
    setTimeout(() => {
      this.checkServerAccessibility(this.$store.state.serverUrl+`/api/get_token?auth=ping`);
    }, 60000); // 60000 milliseconds = 1 minute
  },
};
</script>

<style scoped>
.server-check {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  z-index: 9999;
}
.center-container{
  position: relative;
  width: 420px;
  height: 260px;
  display: flex;
  background: #2d2d2d;
  box-shadow: 0 0 10px 0 #56d500;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
}
.box{
  width: 240px;
  height: 60px;
  position: relative;
}

.text{
  color: #fff;
  font-weight: 300;
  font-size: 25px;
  margin: 20px auto;
  -webkit-animation: fade-in-out 2.5s infinite;
  -moz-animation: fade-in-out 2.5s infinite;
  -o-animation: fade-in-out 2.5s infinite;
  animation: fade-in-out 2.5s infinite;
}

.massage{
  color: #fff;
  font-weight: 300;
  font-size: 15px;
  text-align: center;
  position: relative;
  margin: 70px 15px 0;
}

.comp{
  position: absolute;
  top: 0px;
  width: 80px;
  height: 55px;
  border: 3px solid #fff;
  border-radius: 5px;
}

.comp:after{
  content: '';
  position: absolute;
  z-index: 5;
  top: 19px;
  left: 5px;
  width: 65px;
  height: 10px;
  border-radius: 360px;
  border: 3px solid #fff;
}

.loader{
  position: absolute;
  z-index: 5;
  top: 23px;
  left: 10px;
  width: 8px;
  height: 8px;
  border-radius: 360px;
  background: #fff;
  -webkit-animation: loader 5s infinite linear 0.5s;
  -moz-animation: loader 5s infinite linear 0.5s;
  -o-animation: loader 5s infinite linear 0.5s;
  animation: loader 5s infinite linear 0.5s;
}

.con{
  position: absolute;
  top: 28px;
  left: 85px;
  width: 100px;
  height: 3px;
  background: #fff;
}

.byte{
  position: absolute;
  top: 25px;
  left: 80px;
  height: 9px;
  width: 9px;
  background: #fff;
  border-radius: 360px;
  z-index: 6;
  opacity: 0;
  -webkit-animation: byte_animate 5s infinite linear 0.5s;
  -moz-animation: byte_animate 5s infinite linear 0.5s;
  -o-animation: byte_animate 5s infinite linear 0.5s;
  animation: byte_animate 5s infinite linear 0.5s;
}

.server{
  position: absolute;
  top: 22px;
  left: 185px;
  width: 35px;
  height: 35px;
  z-index: 1;
  border: 3px solid #fff;
  background: #56d500;
  border-radius: 360px;
  -webkit-transform: rotateX(58deg);
  -moz-transform: rotateX(58deg);
  -o-transform: rotateX(58deg);
  transform: rotateX(58deg);
}

.server:before{
  content: '';
  position: absolute;
  top: -47px;
  left: -3px;
  width: 35px;
  height: 35px;
  z-index: 20;
  border: 3px solid #fff;
  background: #56d500;
  border-radius: 360px;
}

.server:after{
  position: absolute;
  top: -26px;
  left: -3px;
  border-left: 3px solid #fff;
  border-right: 3px solid #fff;
  width: 35px;
  height: 40px;
  z-index: 17;
  background: #56d500;
  content: '';
}

/*Byte Animation*/
@-webkit-keyframes byte_animate{
  0%{
    opacity: 0;
    left: 80px;
  }
  4%{
    opacity: 1;
  }
  46%{
    opacity: 1;
  }
  50%{
    opacity: 0;
    left: 185px;
  }
  54%{
    opacity: 1;
  }
  96%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    left: 80px;
  }
}

@-moz-keyframes byte_animate{
  0%{
    opacity: 0;
    left: 80px;
  }
  4%{
    opacity: 1;
  }
  46%{
    opacity: 1;
  }
  50%{
    opacity: 0;
    left: 185px;
  }
  54%{
    opacity: 1;
  }
  96%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    left: 80px;
  }
}

@-o-keyframes byte_animate{
  0%{
    opacity: 0;
    left: 80px;
  }
  4%{
    opacity: 1;
  }
  46%{
    opacity: 1;
  }
  50%{
    opacity: 0;
    left: 185px;
  }
  54%{
    opacity: 1;
  }
  96%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    left: 80px;
  }
}

@keyframes byte_animate{
  0%{
    opacity: 0;
    left: 80px;
  }
  4%{
    opacity: 1;
  }
  46%{
    opacity: 1;
  }
  50%{
    opacity: 0;
    left: 185px;
  }
  54%{
    opacity: 1;
  }
  96%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    left: 80px;
  }
}

/*LOADER*/
@-webkit-keyframes loader{
  0%{
    width: 8px;
  }
  100%{
    width: 63px;
  }
}

@-moz-keyframes loader{
  0%{
    width: 8px;
  }
  100%{
    width: 63px;
  }
}

@-o-keyframes loader{
  0%{
    width: 8px;
  }
  100%{
    width: 63px;
  }
}

@keyframes loader{
  0%{
    width: 8px;
  }
  100%{
    width: 63px;
  }
}


/*FADE IN-OUT*/
@-webkit-keyframes fade-in-out{
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    oapcity: 1;
  }
}

@-moz-keyframes fade-in-out{
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    oapcity: 1;
  }
}

@-o-keyframes fade-in-out{
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    oapcity: 1;
  }
}

@keyframes fade-in-out{
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
  }
  100%{
    oapcity: 1;
  }
}
</style>