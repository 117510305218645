<template>
    <div class="user-nav-container" @focusout="handleFocusOut" tabindex="0">
        <div v-if="$store.state.auth.status" @click="toggleNav"> 
          <div class="row user-nav-btn">
                <div class="col avatar-col">
                    <img :src="'https://www.libravatar.org/avatar/' + this.$store.state.auth.avatar + '?d=mm&s=40'" class="avatar">
                </div>
                <div class="col user-name-col">
                    <h6 class="dropdown-header user-name">{{ $store.state.auth.name }}</h6>
                    <div class="user-type">{{ $store.state.auth.group + ' Member' }}</div>
                </div>
            </div>
        </div>
        <div class="user-dropdown" :class="{'dropdown-open': showNav}">
              <a class="dropdown-item" v-if="!$store.state.auth.pro" @click="eventBus.$emit('pageLoader', true, 'https://ecomhunt.com/pricing?source=app-user-menu', 0, true)"><i class="fa fa-angle-double-up"></i>&nbsp;&nbsp; Upgrade to Pro</a>
              <a class="dropdown-item" @click="eventBus.$emit('pageLoader', true, 'https://ecomhunt.com/account_setting', 0, true)"><i class="fa fa-user"></i>&nbsp;&nbsp; Ecomhunt account</a>
              <a class="dropdown-item logout" @click="logout"><i class="fa-solid fa-right-from-bracket"></i>&nbsp;&nbsp; Logout</a>
          </div> 
        <div v-if="!$store.state.auth.status">
            <form class="form-inline">
                <button class="btn btn-success head_btn get_started " type="button" @click="login"><b>GET STARTED</b></button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
  data() {
        return {
            showNav: false,
            timeout: null,
            hovering: false,
            
        }
    },
    methods: {
        logout: function () {
            this.$store.dispatch('auth/logout')
            this.$router.push('/login')
        },

        login: function () {
            this.$router.push('/login')
        },

        toggleNav: function () {
            this.showNav = !this.showNav;
        },

        outEvent: function () {
            this.hovering = false;
            this.timeout = setTimeout(() => {
                if (this.showNav && !this.hovering){
                    this.showNav = false;
                }
            }, 2500);
        },

        inEvent: function () {
            this.hovering = true;
            if (!this.showNav){
                this.showNav = true;
            }
        },
        redirect: function (link, target = '_self') {
            window.open(link, target);
        },
        handleFocusOut: function(){
          setTimeout(() => {
              this.showNav = false;
            }, 200);
        }
    },
}
</script>

<style scoped>
.user-dropdown{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}
.dropdown-open{
  max-height: 200px;
}
.dropdown-item i {
    font-size: 18px;
    margin: 0 17px 0 5px;
}

.get_started {
  background-color: #56d500;
  color: #000000;
}
  
  @media only screen and (max-width: 991px) {
    .head_btn{
        font-size: 12px;
        padding: 4px 10px;
        margin-left: 2px;
    }
  }
  
  .head_btn:hover{
    background-color: #4bb900;
    color: #000000;
  }
  .row.user-nav-btn {
    background-color: rgba(1, 1, 1, 0);
    padding: 0;
    height: 60px;
    width: 258px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  .row.user-nav-btn-active {
    background-color: rgba(1, 1, 1, 1);
  }
  
  .row.user-nav-btn:hover {
    background-color: rgba(1, 1, 1, 1);
  }
  
  .col.avatar-col {
    max-width: 56px;
    padding: 10px 7px 0 5px;;
  }
  
  img.avatar {
    width: 3
    5px;
    border-radius: 30px;
  }
  
  .col.user-name-col {
    padding: 2px 0 0 5px;
    font-size: 12px;
    font-weight: 300;
  }
  
  .dropdown-header.user-name{
    color: #ffffff;
    padding: 14px 0 0;
    font-size: 12px;
  }
  
  .user-type {
    color: #56d500;
  }
  
  .user-dropdown a.dropdown-item {
    color: #8a8a8a;
    font-weight: 400;
    font-size: 16px;
    margin: 16px 0 6px;
    cursor: pointer;
  }

  
  .user-dropdown .dropdown-divider {
    border-top: 3px solid #56d500;
  }
  a.dropdown-item:hover{
    color: #fff;
    background-color: #010101;
  }
</style>