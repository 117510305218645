<template>
  <div class="mobile-nav-container">
      <nav class="navbar navbar-dark fixed-top mobile-nav">
          <a class="navbar-brand" href="/">
              <img src="@/assets/img/logo.png" height="30" alt="" class="head_logo">
          </a>
          
          <form class="form-inline">
              <button class="btn btn-success head_btn get_started" v-if="!$store.state.auth.status" type="button" @click="login"><b>GET STARTED</b></button>
              <button class="btn btn-outline-success head_btn" type="button" @click="toggleNav">
                  <i class="fas fa-bars"></i>
              </button>
          </form>
      </nav>
      <div class="mob-menu-container" v-if="showNav">
          <div class="mob-close-btn" @click="toggleNav"><i class="fas fa-times"></i></div>
          <div class="mob-menu-links-container">
              <navLinks :resourceLink="resourceLink" />
              <button type="button" @click="logout" class="btn btn-outline-danger btn-sm ml-4 mb-1 mt-2" v-if="$store.state.auth.status">Log out</button>
          </div>
      </div>
  </div>
</template>

<script>
import navLinks from '@/components/navLinks.vue'

export default {
    components: { navLinks },
    props: ['resourceLink'],
    data() {
        return {
            showNav: false,
            timeout: null,
            hovering: false,
        }
    },
    methods: {
        logout: function () {
            this.$store.dispatch('auth/logout')
        },

        userType: function () {
            if (this.$store.state.auth.pro){
                return this.$store.state.auth.group + ' Member'
            } else {
                return 'Free Member'
            }
        },

        toggleNav: function () {
            this.showNav = !this.showNav;
        },
    },
    watch: {
        showNav: {
            handler: function () {
                this.$parent.scroll(!this.showNav);
            },
            deep: true
        }
    },
    mounted () {
        this.eventBus.$on('pageLoader', () => {
            this.showNav = false
        });
    },
}
</script>


<style scoped>
.head_btn{
    color: #55D500;
}
</style>
