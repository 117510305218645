<template>
    <div class="modal fade show" v-if="showVideo" @click="closeVideo" v-bind:style="model_styles" id="exampleModalCenter">
        <div class="modal-dialog modal-dialog-centered model_video" role="document">
            <div class="modal-content dark_model">
                <div class="modal-body dark_model_body">
                    <iframe :src="video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [''],
        data() {
            return {
                video: null,
                showVideo: false,
                model_styles: {
                    'display': 'block',
                    'background-color' : 'rgba(0, 0, 0, 0.8)'
                },
            }
        },
        methods: {
            closeVideo() {
                this.showVideo = false
                this.video = null
            }
        },
        mounted () {
            this.eventBus.$on('showVideo', (value) => {
                this.video = value
                setTimeout(() => {
                    this.showVideo = true
                }, 100);
            });
        },
    }
</script>

<style lang="scss" scoped>
.model_video {
    max-width: 55%;
    margin-top: 10px;
}
@media only screen and (max-width: 600px) {
    .model_video {
        max-width: 100%;
        margin-top: 10px;
    }
}
.modal-content {
    max-height: 95vh;
    overflow: hidden;
}
.dark_model {
    background-color: #000;
    overflow: hidden;
}
.dark_model_body {
    padding: 0;
    margin: -4px 0 -12px;
    position: relative;
    padding-bottom: 63.25%;
    height: 0;
    max-height: 90vh;
}
.dark_model_body iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>