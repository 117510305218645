<template>
  <div class="container-fluid main-fluid">

      <navMobile v-if="isMobile && $store.state.auth.status" />
      <navDesktop v-if="!isMobile && $store.state.auth.status" />

      <main class="main" :class="{ 'main-expended' : !$store.state.showNav }">
          <div class="row header-container" v-if="!isMobile && $store.state.auth.status">
          </div>
          <hr class="topHR">
          <div class="container-fluid page-content mt-3">
            <router-view :key="$route.fullPath" />
            <ToastMessage />
          </div>
          <PageLoader v-if="pageLoader" :path="loaderPath" :ext="pageLoaderExt"/>
      </main>
      <!-- loader -->
      <div class="loader_container" v-show="appLoader">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
      <VideoModal />
      <ServerCheck />
  </div>
</template>

<script>
import navDesktop from '@/components/navDesktop.vue'
import navMobile from '@/components/navMobile.vue'
import ToastMessage from '@/components/toastMessage.vue'
import PageLoader from '@/components/pageLoader.vue'
import VideoModal from './components/videoModal.vue'
import ServerCheck from '@/components/ServerCheck.vue'



export default {
  name: 'ecomhunt-app-home',
  components: {
    navDesktop,
    navMobile,
    ToastMessage,
    PageLoader,
    VideoModal,
    ServerCheck
  },
  data () {
        return {
            appLoader: true,
            pageLoader: true,
            searchLoader: false,
            isMobile: false,
            mobileScroll: false,
            page: 'login-page',
            loaderPath: null,
            pageLoaderExt: false
        }
    },

    methods: {
        checkMobile : function () {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <= 991){this.isMobile = true;} else { this.isMobile = false;}
            if (this.windowWidth <= 550){this.mobileScroll = true;} else {this.mobileScroll = false;}
            if (this.windowWidth <= 1876){
                if (this.$store.state.showNav) this.$store.commit('navToggle')
            }else {
              if (!this.$store.state.showNav) this.$store.commit('navToggle')
            }
        },

        scroll: function (scroll) {
            if (scroll) {
                document.body.style.overflow = "auto";
                document.body.style.height = "auto";
            } else {
                document.body.style.overflow = "hidden";
                document.body.style.height = "100%";
            }
        },
    },

    mounted () {
        document.title = 'Ecomhunt App'
        this.checkMobile();
        window.addEventListener('resize', this.checkMobile);

        setTimeout(() => {
            this.pageLoader = false
        }, 200);

        this.eventBus.$on('pageLoader', (value, path, delay, ext = false) => {
            setTimeout(() => {
                this.loaderPath = path
                this.pageLoader = value
                this.pageLoaderExt = ext
            }, delay);
        });

        this.eventBus.$on('logout', () => {
            setTimeout(() => {
                this.appLoader = true
            }, 100);
        });

        this.appLoader = false
    }
}
</script>

<style scoped>
.topHR{
    margin-top: 20px;
}
.announcement-bar {
  position: relative;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
}
.announcement-bar a {
  font-size: 17px;
  border-bottom: 2px solid yellow;
  color: yellow;
  text-decoration: none;
}
</style>