<template>
    <div class="toasts-container">
        <div class="toast show toast-enter" v-for="notification in notifications" :key="notification.id">
            <div class="toast-header">
                <strong class="mr-auto">{{ notification.title }} </strong>{{ notification.time }}
                <button type="button" class="ml-2 mb-1 close" @click="removeNotification(notification.id)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="toast-body">
                {{ notification.msg }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            notifications: [],
        }
    },

    methods: {
        notify_msg(title, massage){
            const id = Date.now();
            this.notifications.push({
                id,
                title,
                time: this.getTime(),
                msg: massage,
                enter: true,
                timeout: setTimeout(()=>{
                    this.removeNotification(id);
                }, 12000)
            });
        },

        removeNotification(id) {
            const index = this.notifications.findIndex(n => n.id === id);
            clearTimeout(this.notifications[index].timeout);
            this.notifications.splice(index, 1);
        },
        getTime(){
            const date = new Date();
            const hour = date.getHours().toString().padStart(2, '0');
            const minute = date.getMinutes().toString().padStart(2, '0');
            const second = date.getSeconds().toString().padStart(2, '0');
            const time = `${hour}:${minute}:${second}`;
            return time;
        }
    },

    created(){
        this.eventBus.$on('notify', (title, message) => {
            this.notify_msg(title, message)
        });
    }
}
</script>

<style scoped>
.toasts-container{
    position: fixed; 
    bottom: 10px;
    left: 280px;
    z-index: 15000;
}
.toast {
    background-color: #000;
    opacity: 0;
}

.toast-enter {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% { max-height: 0; opacity: 0;} 
    30% { margin-bottom: -20px; margin-top: 20px; opacity: 0;}
    50% {max-height: 300px;}
    100% { opacity: 1; margin-bottom: 0; margin-top: 0;}
}

@-moz-keyframes fadeIn {
    0% { opacity: 0; margin-bottom: -20px; }
    100% { opacity: 1; margin-bottom: 0; }
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; margin-bottom: -20px; }
    100% { opacity: 1; margin-bottom: 0; }
}

@-o-keyframes fadeIn {
    0% { opacity: 0; margin-bottom: -20px; }
    100% { opacity: 1; margin-bottom: 0; }
}

@-ms-keyframes fadeIn {
    0% { opacity: 0; margin-bottom: -20px; }
    100% { opacity: 1; margin-bottom: 0; }
}

.toast-header {
    background-color: #212121;
    color: #6ddc5c;
}
button.ml-2.mb-1.close {
    color: #fff;
    text-shadow: 0 1px 0 #000;
}
</style>