<template>
    <div class="sidbar-container sidebar" :class="{ 'sidebar-collapsed' : !$store.state.showNav }">
        <span class="menu-toggle-btn" @click="$store.commit('navToggle')" :class="{ 'toggle-close' : $store.state.showNav }">
            <span v-if="!$store.state.showNav"><i class="fa-solid fa-angle-right"></i></span>
            <span v-if="$store.state.showNav"><i class="fa-solid fa-angle-left"></i></span>
        </span>
        <div class="MenuContainer" @mouseover="hovering = true" @mouseleave="hovering = false">
            <nav id="sidebarMenu" 
                class="sidebar" 
                :class="{ 'sidebar-collapsed' : !$store.state.showNav }" >
                <div class="position-sticky pl-1">
                    <a class="navbar-brand logo" :class="{ 'logo-collapsed' : !$store.state.showNav }" href="/" target="_self">
                        <img src="@/assets/img/logo.png" alt="ecomhunt logo" width="210px">
                    </a>
                    <navLinks />
                    <!-- <div class="fixed-bottom collapse-btn" :class="{ 'collapse-btn-collapsed' : !$store.state.showNav }" @click="$store.commit('navToggle')">
                        <span v-show="$store.state.showNav"><i class="fas fa-caret-square-left"></i></span>
                        <span v-show="!$store.state.showNav"><i class="fas fa-caret-square-right"></i></span>
                    </div> -->
                    <NavUser class="user-menu" :class="{ 'collapse-btn-collapsed' : !$store.state.showNav }" />
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import navLinks from './navLinks.vue'
import NavUser from './navUser.vue'

export default {
  components: { navLinks, NavUser },
  
  data () {
        return {
          timeout: null,
          hovering: false,
          flag: false,
          resetTimeout: null
        }
    },

    watch: {
      hovering: {
            handler: function(newValue) {
                if ( this.$store.state.showNav === false && newValue === true && this.flag === false){
                    this.flag = true
                    this.timeout = setTimeout(() => {
                        if (this.hovering){
                            this.$store.commit('navToggle');
                            this.flag = false
                        }
                    }, 200);
                }
                this.resetTimeoutL = setTimeout(() => {
                        this.flag = false
                }, 1000);
            },
            deep: true
        }
    },
}
</script>

<style scoped>
.menu-toggle-btn{
    position: absolute;
    right: -35px;
    top: 30px;
    width: 28px;
    height: 28px;
    font-size: 17px;
    padding: 3px 9px;
    color: #8a8a8a;
    background-color: #131313;
    border-radius: 30px;
    cursor: pointer;
    transition: right 0.7s ease-in-out;
    z-index: 101;
}
.toggle-close{
    right: -15px;
}
.user-menu{
    position: fixed;
    overflow: hidden;
    width: 268px;
    left: 0;
    bottom: 0;
    color: #8a8a8a;
    border-top: 2px solid #56d500;
    transition: width 0.7s ease-in-out;
    background-color: #010101;
}

.collapse-btn-collapsed{
    width: 69px;
}
  

</style>
