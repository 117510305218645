import { render, staticRenderFns } from "./navUser.vue?vue&type=template&id=39d881fc&scoped=true&"
import script from "./navUser.vue?vue&type=script&lang=js&"
export * from "./navUser.vue?vue&type=script&lang=js&"
import style0 from "./navUser.vue?vue&type=style&index=0&id=39d881fc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d881fc",
  null
  
)

export default component.exports