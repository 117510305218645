import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import eventBus from '@/event-bus'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: function () {
      return import(/* webpackChunkName: "HomeView" */ '../views/home/HomeView.vue')
    },
    meta: { title: 'Home' }
  },
  {
    path: '/classic',
    name: 'classic',
    component: function () {
      return import(/* webpackChunkName: "ClassicView" */ '../views/classic/search/ClassicView.vue')
    },
    meta: { title: 'Classic' }
  },
  {
    path: '/product/:id',
    name: 'product',
    component: function () {
      return import(/* webpackChunkName: "ProductView" */ '../views/classic/product/ProductView.vue')
    },
  },
  {
    path: '/product/sellers/:id',
    name: 'sellers',
    component: function () {
      return import(/* webpackChunkName: "SellersView" */ '../views/classic/sellers/SellersView.vue')
    },
    meta: { title: 'Product Sellers' }
  },
  {
    path: '/live',
    name: 'live',
    component: function () {
      return import(/* webpackChunkName: "LiveView" */ '../views/live/LiveView.vue')
    },
    meta: { title: 'Live' }
  },
  {
    path: '/tracker',
    name: 'tracker',
    component: function () {
      return import(/* webpackChunkName: "TrackerView" */ '../views/tracker/TrackerView.vue')
    },
    meta: { title: 'Tracker' }
  },
  {
    path: '/adam',
    name: 'adam',
    component: function () {
      return import(/* webpackChunkName: "adamView" */ '../views/adam/adamView.vue')
    },
    meta: { title: 'Adam' }
  },
  {
    path: '/adam/product/:id',
    name: 'adamProduct',
    component: function () {
      return import(/* webpackChunkName: "ProductView" */ '../views/adam/product/ProductView.vue')
    },
  },
  {
    path: '/alerts',
    name: 'alerts',
    component: function () {
      return import(/* webpackChunkName: "alertsView" */ '../views/alerts/alertsView.vue')
    },
    meta: { title: 'Alerts' }
  },
  {
    path: '/lucky',
    name: 'lucky',
    component: function () {
      return import(/* webpackChunkName: "LuckyView" */ '../views/lucky/LuckyView.vue')
    },
    meta: { title: 'Lucky' }
  },
  {
    path: '/courses',
    name: 'courses',
    component: function () {
      return import(/* webpackChunkName: "coursesView" */ '../views/courses/coursesView.vue')
    },
    meta: { title: 'Courses' }
  },
  {
    path: '/course/:id',
    name: 'course',
    component: function () {
      return import(/* webpackChunkName: "courseView" */ '../views/courses/courseView.vue')
    },
    meta: { title: 'Course' }
  },
  {
    path: '/ebooks',
    name: 'ebooks',
    component: function () {
      return import(/* webpackChunkName: "ebooksView" */ '../views/ebooks/ebooksView.vue')
    },
    meta: { title: 'eBooks' }
  },
  {
    path: '/support',
    name: 'support',
    component: function () {
      return import(/* webpackChunkName: "supportView" */ '../views/support/supportView.vue')
    },
    meta: { title: 'Support' }
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import(/* webpackChunkName: "login" */ '../views/login/loginView.vue')
    },
    meta: { publicRoute: true, title: 'Login' }
  },
  {
    path: '/access-denied',
    name: 'Access Denied',
    component: function () {
      return import(/* webpackChunkName: "access-denied" */ '../views/noAccess.vue')
    },
    meta: { publicRoute: true, title: 'Access Denied' }
  },
  {
    path: '*',
    name: 'Not Found',
    component: function () {
      return import(/* webpackChunkName: "notFound" */ '../views/notFound.vue')
    },
    meta: { publicRoute: true, title: '404 Not Found' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

async function validateAuth () {
  if (store.state.token === null){
    await store.dispatch('getToken')
  }else{
    await store.dispatch('auth/authStatus')
  }
}

router.beforeEach(async (to, from, next) => {
  eventBus.$emit('pageLoader', true, to.name, 0)
  await validateAuth()
  if (!to.matched.some(route => route.meta.publicRoute)) {
    if (!store.state.auth.status) {
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

const baseTitle = 'Ecomhunt App | '
router.afterEach((to, from) => {
  eventBus.$emit('routed', (to, from) );
  eventBus.$emit('pageLoader', false, null, 0)
  Vue.nextTick(() => {
    if(to.meta.title)
      document.title = baseTitle + to.meta.title
  })
})
export default router
