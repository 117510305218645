import axios from 'axios'
import eventBus from '@/event-bus'

const auth = {
    namespaced: true,
    state: {
        status: false,
        pro: false,
        group: null,
        administrator: false,
        group_level : 1,
        permissions_group : {},
        name: '',
        avatar: '',
        spins: 5,
        survey: false,
        login_message: null,
        user_id: null
    },
    mutations: {
        updateProperties(state, properties) {
            Object.assign(state, properties)
        },
        updateProperty(state, {key, value}) {
            state[key] = value
        },
        updateSpins(state, spins) {
            state.spins = spins
        },
        resetState(state){
            Object.assign(state, {
                status: false,
                pro: false,
                group: null,
                administrator: false,
                group_level : 1,
                permissions_group : {},
                name: '',
                avatar: '',
                login_message: null,
                user_id: null
            })
        }
    },
    actions: {
        async login({ commit, rootState }, form) {
            const Obj = {}
            await axios({
                method: 'post',
                url: rootState.serverUrl + '/ext_auth',
                headers: {'content-type': 'application/json'},
                timeout: 15000,
                withCredentials: true,
                data: form
            }).then(response => {
                Obj.status = response.data.auth;
                if (Obj.status === true) {
                    Obj.permissions_group = response.data.permissions_group;
                    Obj.name = response.data.data.name;
                    Obj.group = response.data.data.user_group;
                    Obj.group_level = response.data.data.group_level;
                    Obj.avatar = response.data.data.avatar;
                    Obj.administrator = response.data.data.administrator;
                    Obj.survey = response.data.data.survey;
                    Obj.user_id = response.data.data.ext_user_id;
                    Obj.login_message = null
                    if (response.data.data.is_pro) {
                        Obj.pro = true;
                    }
                    window.Tawk_API.setAttributes({
                        'name'  : Obj.name,
                        'user-id': Obj.user_id,
                        'email' : response.data.data.email,
                        'Plan'  : Obj.group,
                        'Pro-User' : Obj.pro ? 'Yes' : 'No'
                    }, function(){});
                    commit('updateProperties', Obj)
                } else {
                    commit('updateProperty', {key: 'login_message', value: response.data.data.massage})
                    commit('resetState')
                    window.localStorage.clear()
                    window.open('/login?msg=1', '_self');
                }
                this.login_process = false;
            }).catch(error => {
                commit('updateProperty', {key: 'login_message', value: 'Unexpected error:' + error})
                commit('resetState')
                window.localStorage.clear()
                window.open('/login', '_self');
            });
        },

        async logout({ commit, rootState }) {
            try {
                const response = await axios({
                    method: 'get',
                    url: rootState.serverUrl+`/logout`,
                    timeout: 15000,
                    withCredentials: true,
                })
                if (response.status === 200) {
                    eventBus.$emit('logout')
                    commit('resetState')
                    window.localStorage.clear()
                    window.open('/login', '_self');
                } 
            } catch (error) {
                eventBus.$emit('notify', 'Something went wrong', 'Unexpected error:' + error)
            }
        },

        async authStatus({ commit, rootState, dispatch }) {
            const Obj = {}
            await axios({
                method: 'get',
                url: rootState.serverUrl+`/api/auth_status?token=`+rootState.token,
                timeout: 15000,
                withCredentials: true,
            }).then(response => {
                if (response.data.status === 'failed'){
                    dispatch('getToken', null, { root: true })
                }
                if (response.data.auth) {
                    Obj.permissions_group = response.data.permissions_group;
                    Obj.status = response.data.auth;
                    Obj.name = response.data.data.name;
                    Obj.group = response.data.data.user_group;
                    Obj.group_level = response.data.data.group_level;
                    Obj.administrator = response.data.data.administrator;
                    Obj.survey = response.data.data.survey;
                    Obj.user_id = response.data.data.ext_user_id;
                    if (response.data.data.is_pro) {
                        Obj.pro = true;
                    }
                    Obj.spins = response.data.data.spins;
                    Obj.avatar = response.data.data.avatar;
                    
                    commit('updateProperties', Obj)
                }else{
                    commit('resetState')
                }
                eventBus.$emit('autCheckCompleted');
            }).catch(error => {
                eventBus.$emit('notify', 'Something went wrong', 'Unexpected error:' + error)
                commit('resetState')
                commit('setToken', null, { root: true })
            });
        }
    }
}
  
export default auth