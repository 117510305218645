import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexPersist from 'vuex-persist'
import auth from './auth'
import classic from './classic'
import eventBus from '@/event-bus'

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
  key: 'dataStore',
  storage: window.localStorage,
})

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    showNav: true,
    token: null,
    serverUrl: process.env.VUE_APP_API_URL,
    showSidebar: true
  },
  mutations: {
    navToggle (state) {
      state.showNav = !state.showNav
    },
    setToken (state, token){
      state.token = token;
    }
  },
  actions: {
    async getToken({ commit }){
      try{
        const response =await axios({
            method: 'get',
            url: this.state.serverUrl+`/api/get_token?auth=`+process.env.VUE_APP_API_TOKEN,
            timeout: 10000,
            headers: {"Content-Type": "application/json"},
            withCredentials: true,
        })
        if (response.data.status === true) {
          commit('setToken', response.data.token)
        }else{
          commit('setToken', null)
          commit('auth/resetState')
          
          setTimeout(() => {
            eventBus.$emit('notify', 'Something went wrong', 'Unexpected server error, please try again if the issue persist please contact support')
          }, 2000);
        }
      } catch(error) {
        commit('setToken', null)
        commit('auth/resetState')
        setTimeout(() => {
          eventBus.$emit('notify', 'Something went wrong', 'Unexpected error cannot connect to server - please try again later or contact support')
        }, 2000);
      }
    }
  },
  getters:{
  },
  modules: {
    auth,
    classic
  }
})
